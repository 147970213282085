import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Service from "./service/service"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const ServicesListingsAll = () => {
  const { allWpService } = useStaticQuery(SERVICES_LIST_LITIGATION_QUERY)

  return (
    <ServicesListWrapper>
      {allWpService.edges.map((item) => (
        <Service item={item.node} key={item.node.slug} />
      ))}
    </ServicesListWrapper>
  )
}
export default ServicesListingsAll

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const ServicesListWrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 60px auto;
  align-content: baseline;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
    margin: ${(props) => props.theme.gutter};
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    margin: 0;
  }
`

///////////////////////////////////////
// 🔎 Queries
///////////////////////////////////////
const SERVICES_LIST_LITIGATION_QUERY = graphql`
  query ServicesListLit {
    allWpService(
      filter: {
        categories: { nodes: { elemMatch: { databaseId: { eq: 18 } } } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          acfPageHeader {
            heading
            subHeading
            compact
            headerImage {
              sourceUrl
            }
          }
        }
      }
    }
  }
`
