import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Service = ({ item }) => (
  <ItemWrapper
    bg={item.acfPageHeader.headerImage.sourceUrl}
    to={`/services/${item.slug}`}
  >
    <ContentBox bg={item.acfPageHeader.headerImage.sourceUrl}>
      <h3>{item.title}</h3>
      <div className="blur" />
    </ContentBox>
  </ItemWrapper>
)
export default Service

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const ItemWrapper = styled(Link)`
  display: grid;
  align-items: end;
  border: 1px solid ${(props) => props.theme.green};
  background-color: #5c5d5dd9;
  background-image: url(${(props) => props.bg});
  background-position: bottom center;
  background-size: 105% auto;
  transition: all 0.15s ease;
  border-radius: 5px;
  min-height: 300px;
  :hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
`

const ContentBox = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-height: 100%;
  .blur {
    padding: 20px;
    background-image: url(${(props) => props.bg});
    background-size: 105% auto;
    background-position: bottom center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100px;
    z-index: 99999;
    filter: blur(4px);
    overflow: hidden;
  }
  h3 {
    position: inherit;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    min-height: 100px;
    z-index: 999999;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    margin: 0px;
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1.3;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
  }
`
