import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SeoComponent from "../components/seoComponent"
import { graphql, useStaticQuery } from "gatsby"
import posed from "react-pose"
import PageHeader from "../components/pageHeader/pageHeader"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Container from "../components/Container"
import styled from "styled-components"
import ServicesListingsLitigation from "../components/services/litigationServices"
import TeamListingsLitigation from "../components/team/teamByLitigation"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const LitigationPage = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    let fadeIn = setTimeout(() => setIsVisible(true), 500)
    return () => {
      clearTimeout(fadeIn)
    }
  }, [])

  const { page } = useStaticQuery(LITIGATION_PAGE_CONTENT)

  return (
    <Layout>
      <Header />
      <SeoComponent seo={page.seo} />
      <PageAnimationWrapper pose={isVisible ? "visible" : "hidden"}>
        <PageHeader
          heading={page.acfPageHeader.heading}
          subHeading={page.acfPageHeader.subHeading}
          bg={page.acfPageHeader.headerImage.sourceUrl}
          compact={page.acfPageHeader.compact}
          mobileHeight="310px"
        />
        <Container>
          <IntroParagraph>
            <p
              dangerouslySetInnerHTML={{
                __html: page.content,
              }}
            />
          </IntroParagraph>
        </Container>
        <Container bg="#dddddd78">
          <LitigationServices>
            <h2>Our Litigation Services</h2>
            <MiddleLine />
            <ServicesListingsLitigation />
          </LitigationServices>
        </Container>
        <Container bg="#093334">
          <OurLitigators>
            <h2>Our Litigators</h2>
            <LeftLine />
            <TeamListingsLitigation />
          </OurLitigators>
        </Container>
      </PageAnimationWrapper>
      <Footer />
    </Layout>
  )
}
export default LitigationPage

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const PageAnimationWrapper = posed.div({
  visible: { y: 0, opacity: 1 },
  hidden: { y: 100, opacity: 0 },
})

const MiddleLine = styled.div`
  background-color: ${(props) => props.theme.gold};
  height: 4px;
  width: 80px;
  margin: 20px auto;
`

const LeftLine = styled.div`
  background-color: ${(props) => props.theme.gold};
  height: 4px;
  width: 80px;
  margin: 20px 0;
`

const IntroParagraph = styled.div`
  column-count: 2;
  column-gap: 40px;
  @media (max-width: 600px) {
    column-count: 1;
  }
`

const LitigationServices = styled.div`
  h2 {
    text-align: center;
    color: ${(props) => props.theme.green};
  }
`

const OurLitigators = styled.div`
  h2 {
    color: #fff;
  }
`

///////////////////////////////////////
// 🔎 Queries
///////////////////////////////////////
const LITIGATION_PAGE_CONTENT = graphql`
  query LitigationDetails {
    page: wpPage(databaseId: { eq: 57 }) {
      content
      seo {
        ...SEO
      }
      acfPageIntro {
        introParagraph
      }
      acfPageHeader {
        heading
        subHeading
        compact
        headerImage {
          sourceUrl
        }
      }
    }
  }
`
